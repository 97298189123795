import Service from "../Service";
const resource = "prepackingdispatch/";

export default {
  

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    
    list(fcb, requestID){
            return Service.post(resource + "list", fcb,{
                params: { requestID: requestID }
            });
    },
    listguide(fcb, requestID){
        return Service.post(resource + "listguide", fcb,{
            params: { requestID: requestID }
        });
   }
}