import Service from "../Service";
const resource = "chamberprepacking/";

export default {
  

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    
    list(fcb, requestID){
            return Service.post(resource + "list", fcb,{
                params: { requestID: requestID }
            });
    },
   detail(parameters, requestID) {        
        return Service.post(resource + "detail", parameters, {
            params: { requestID: requestID },
        });
    },
        ResumenDetail(fcb, requestID){
            return Service.post(resource + "resumendetail", fcb,{
                params: { requestID: requestID }
            });
    },

    agrupar(fcb, requestID)
    {
            return Service.post(resource + "agrupar", fcb,{
                params: { requestID: requestID }
            });
    },
    GetCampaignsChamber(fcb, requestID)
    {
            return Service.post(resource + "getcampaignschamber", fcb,{
                params: { requestID: requestID }
            });
    },

    listpicking(fcb, requestID){
        return Service.post(resource + "listpicking", fcb,{
            params: { requestID: requestID }
        });
   },
   listpickingDispatch(fcb, requestID){
    return Service.post(resource + "listpickingdispatch", fcb,{
        params: { requestID: requestID }
    });
},
}
