<template>
    <!-- <div> -->
        <v-card>
            <v-card-title>
                <s-toolbar color="primary" dark label="DEPACHO DE PACKING LIST" close @close="close()" save @save="save()"></s-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row justify="center">
                        <v-col cols="2" >
                            <s-date label="FECHA DE CARGA" v-model="item.DateLoading">
                            </s-date>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="N° CONTENEDOR" v-model="item.NumberContainer">
                            </s-text>
                        </v-col>
                   
                        <v-col cols="2">
                            <s-text label="CLIENTE" v-model="item.CumName"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="CANTIDAD DE CAJAS" number v-model="item.NumberBox"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row justify="center"> -->
                        <v-col cols="2">
                            <s-text label="N° ORDEN DE COMPRA" v-model="item.NumberPurchase"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="PESO NETO" decimal v-model="item.WeightNet"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row> -->
                        <v-col cols="2">
                            <s-text label="DESCRIPCION DE PRODUCTO" v-model="item.ProductDescription"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="PESO BRUTO" decimal v-model="item.WeightGross"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row justify="center"> -->
                        <v-col cols="2">
                            <s-text label="VARIEDAD" v-model="item.VrtName"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="TEMPERATURA" number v-model="item.Temperature"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row> -->
                        <v-col cols="2">
                            <s-text label="PRESENTACION" v-model="item.Presentation"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="PRECINTO ADUANAS" v-model="item.CustomsSeal"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row justify="center"> -->
                        <v-col cols="2">
                            <s-text label="FORMATO" v-model="item.FormatString"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="PRECINTO NAVIERA" v-model="item.ShippingSeal"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row> -->
                        <v-col cols="2">
                            <s-text label="BOOKING" v-model="item.Booking"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="PRECINTO SUNSHINE" v-model="item.SealSunshine"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row justify="center" > -->
                        <v-col cols="2">
                            <s-text label="NAVE" v-model="item.Ship"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="STICKERS" v-model="item.Stickers"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row> -->
                        <v-col cols="2">
                            <s-text label="NAVIERA" v-model="item.ShippingCompany"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="OTROS STICKERS" v-model="item.OthersStickers"></s-text>
                        </v-col>
                    <!-- </v-row>
                    <v-row justify="center"> -->
                        <v-col cols="2">
                            <s-text label="PUERTO DESTINO" v-model="item.DestinationPort"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text label="N° TERMOREGISTRO" v-model="item.NumberThermoregistration"></s-text>
                        </v-col>
                        <v-col cols="4">
                            <s-textarea label="Observacion " v-model="item.Observations"></s-textarea>
                        </v-col>
                    </v-row>
                    <div class="row">
                        <div class="col">
                            <v-data-table
                                dense
                                :headers="headersPrePacking"
                                :items="ItemsresumenDetail"
                                :items-per-page="-1"
                                disable-sort>

                            </v-data-table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-2 offset-5">
                            <s-text label="Cantidad de Cajas" v-model="QuantityBoxes"></s-text>
                            
                        </div>
                    
                        <div class="col-2 offset-1">
                            <s-text label="Peso Neto (KG)" v-model="QuantityBoxesWeight"></s-text>
                            
                        </div>
                    </div>
                </v-container>

            </v-card-text>

        </v-card>
        
    <!-- </div> -->
</template>

<script>
    
    import _sPrePackingDispatch from "@/services/FrozenProduction/FrzChamberPrePackingDispatch.js";
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";
    export default {
        
        props:{
            SelectPrePacking:{
                default: null,
                type:Object,
            }
        },
        data(){
            return {
                QuantityBoxes: 0,
                QuantityBoxesWeight: 0,
                PrePackingDetails: [],
                headersPrePacking: [
                    { text: "DESCRIPCION DEL PRODUCTO", value: "descriptionProducto", width: 20 }, 
                    { text: "CÓDIGO DE PRODUCTO", value: "TraceabilityID", width: 20 },
                    { text: "CANTIDAD DE CAJAS", value: "TotalBox", width: 20 },   
                    { text: "PESO NETO (KG)", value: "TotalBagWeight", width: 20 },
                    { text: "FECHA DE VENCIMIENTO", value: "DueDateString", width: 20 },
                ],
                item: {},
                ItemsresumenDetail: [],
            }
        },
        methods:{
            close(){
                this.$emit("closedialogDispatch");
            },
            save(){
                
                this.item.CppID = this.SelectPrePacking.CppID;
                this.item.NumberBox = this.QuantityBoxes;
                this.item.WeightNet = this.QuantityBoxesWeight;
                this.$fun.alert("Desea registrar informacion?", "question").then((r) =>{
                    if(r.value){
                        _sPrePackingDispatch.save(this.item, this.$fun.getUserID()).then((r) =>{
                            if(r.status == 200){
                                this.$fun.alert("Se registro correctamente", "success");
                                
                            }
                        });
                    }
                });
            },   
            ResumenDetail(){
                _sPrePacking.ResumenDetail(this.SelectPrePacking, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.ItemsresumenDetail = r.data;     
                        this.ItemsresumenDetail.forEach(element => {
                            element.descriptionProducto = element.TypeCultiveName + ' - '+ element.VrtName;
                        });       
                        console.log('sss',this.ItemsresumenDetail);                       
                    }
                })
            },         
        },
        watch:{
            SelectPrePacking(){
                this.PrePackingDetails = this.SelectPrePacking.ListPackingDetails;                
            }
        },
        
        created(){
            if(this.SelectPrePacking.ListPackingDetails != undefined) {

                console.log('1',this.SelectPrePacking); 
                this.PrePackingDetails = this.SelectPrePacking.ListPackingDetails;
                
                let QuantityBoxes = 0;
                let QuantityBoxesWeight = 0;
                this.PrePackingDetails.forEach((element) => {
                    QuantityBoxes = QuantityBoxes + parseInt(element.TotalBox);
                    QuantityBoxesWeight = QuantityBoxesWeight + parseFloat(element.TotalBagWeight);
                });
                this.QuantityBoxes = QuantityBoxes;
                this.QuantityBoxesWeight = QuantityBoxesWeight;

                // this.item = this.SelectPrePacking;
                if(this.SelectPrePacking.ChamberGuide == null)
                {
                    this.item.WeightNet = QuantityBoxesWeight;
                    this.item.NumberBox = QuantityBoxes;
                    this.item.CumName = this.SelectPrePacking.CumName;
                    this.item.ProductDescription = this.SelectPrePacking.TypeCultiveName;
                    this.item.Presentation = this.SelectPrePacking.TypePackingName;
                    this.item.FormatString = this.SelectPrePacking.TypeCutName;
                }else{
                    this.item = this.SelectPrePacking.ChamberGuide;
                }
                
                this.ResumenDetail();
            }
            else{        
                console.log(this.SelectPrePacking);        
                this.item = this.SelectPrePacking;
                this.ResumenDetail();
            }
        }
    }
</script>