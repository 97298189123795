<template>      
    <v-card>
        <div class="modal">
            <div class="modal-content">
                <div class="modal-header">      
                    <s-toolbar color="primary" dark label="Imprimir PDF " close @close="close()" print @print="PrintPdf()"></s-toolbar>
                </div>
                <div  id="testHtml" ref="testHtml">
                    <div >                                           
                        <div>
                            <img  width="200px"
                                
                                src="../../../../../public/static/logo_sp.png"
                                alt="User Image"
                                ref="userImage"
                            />
                        </div>
                        <div class="font-weight-bold text-h4 ma-0 pa-5">
                            PACKING LIST
                        </div>   
                        <div style="align-items: center;" >
                            <div>                                                                            
                                <table>
                                    <tr >
                                        <td >FECHA DE CARGA</td>
                                        <td class="mt-1">{{   ": " + this.$moment(this.PrePackingDispatch.DateLoading).format("YYYY-MM-DD")  }}</td>
                                        <td>N° CONTENEDOR</td>
                                        <td class="mt-1">{{ ": " + this.PrePackingDispatch.NumberContainer }}</td>
                                    </tr>
                                    <tr>
                                        <td >CLIENTE</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.CumName }}</td>
                                        <td >CANTIDAD DE CAJAS</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.NumberBox }}</td>
                                    </tr>
                                    <tr>
                                        <td class="">N° ORDEN DE COMPRA</td>
                                        <td class="mt-1">{{  ": " +this.PrePackingDispatch.NumberPurchase }}</td>
                                        <td class="">PESO NETO</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.WeightNet }}</td>
                                    </tr>
                                    <tr>
                                        <td class="">DESCRIPCION DE PRODUCTO</td>
                                        <td class="mt-1">{{  ": " +this.PrePackingDispatch.ProductDescription }}</td>
                                        <td >PESO BRUTO</td>
                                        <td class="mt-1"> {{ ": " +this.PrePackingDispatch.WeightGross }}</td>
                                    </tr>
                                    <tr>
                                        <td >VARIEDAD</td>
                                        <td class="mt-1">{{  ": " +this.PrePackingDispatch.VrtName}}</td>
                                        <td >TEMPERATURA</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.Temperature}}</td>
                                    </tr>
                                    <tr>                                                   
                                        <td >PRESENTACION</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.Presentation }}</td>
                                        <td >PRECINTO ADUANAS</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.CustomsSeal }}</td>
                                    </tr>
                                    <tr>
                                        <td class="">FORMATO</td>
                                        <td class="mt-1">{{ ": " +this.PrePackingDispatch.FormatString }}</td>
                                        <td >PRECINTO NAVIERA</td>
                                        <td class="mt-1"> {{ ": " +this.PrePackingDispatch.ShippingSeal}}</td>
                                    </tr>
                                
                                <tr>
                                    <td >BOOKING</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.Booking }}</td>
                                    <td >PRECINTO SUNSHINE</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.SealSunshine}}</td>
                                </tr>
                                <tr>
                                    <td >NAVE</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.Ship }}</td>
                                    <td >STICKERS</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.Stickers}}</td>
                                </tr>
                                <tr>
                                    <td >NAVIERA</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.ShippingCompany }}</td>
                                    <td >OTROS STICKERS</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.OthersStickers}}</td>
                                </tr>
                                <tr>
                                    <td >PUERTO DE DESTINO</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.DestinationPort }}</td>
                                    <td >N° TERMOREGISTRO</td>
                                    <td class="mt-1">{{ ": " +this.PrePackingDispatch.NumberThermoregistration}}</td>
                                </tr>
                            </table>
                            <!-- </v-col> -->
                        </div>                                
                    </div>  
                </div>
                <div style="align-items: center;"  >                    
                    <table  class="tablecss" border="1" density="compact">
                        <thead>
                            <tr>
                                <th ></th>
                                <th colspan="5" >CAJAS POR CODIGO DE PRODUCCION</th>
                            </tr>
                            <tr class="tableth">
                                <th >DESCRIPCION DEL PRODUCTO</th>
                                <th >LOTE DE PRODUCCION</th>
                                <th >CANTIDAD DE CAJAS</th>
                                <th >PESO NETO(KG)</th>
                                <th >FECHA DE VENCIMIENTO</th>
                                <th >DATOS ADICIONALES</th>
                            </tr>  
                        </thead>  
                        <tr v-for="(item, i ) in PrePackingDispatch.dispatchDetails" :key="i">
                            <td>{{ item.DescriptionProduct }}</td>
                            <td >{{ item.TraceabilityID }}</td>
                            <td >{{ item.TotalBox }}</td>
                            <td >{{ item.TotalBagWeight }}</td>
                            <td > {{ item.DueDateString }}</td>
                            <td ></td>
                        </tr>
                    </table>                    
                </div>
                <div   >                                    
                    <div>
                        <p>RESUMEN</p>
                        <table  border="1" >
                            <thead>
                                <tr class="tableth">
                                    <th >DESCRIPCION DEL PRODUCTO</th>
                                    <th >CANTIDAD DE CAJAS</th>
                                    <th >PESO NETO (KG)</th>
                                </tr>
                            </thead>
                            <tr>
                                <td></td>
                                <td>{{ this.PrePackingDispatch.NumberBox }}</td>
                                <td>{{ this.PrePackingDispatch.WeightNet }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div >
                    <table>
                        <tr >
                            <td ><div class="linea"></div></td>
                            <td ><div class="linea"></div></td>
                            <td ><div class="linea"></div></td>
                        </tr>	
                        <tr>
                            <td style="margin: -20px;">V°B° Asist. de Alamcen y Despachos</td>
                            <td style="margin: -20px;">V°B° Supervisor de Aseg. de la Calidad</td>
                            <td style="margin: -20px;">V°B° Sup. de Almacen y Despachos</td>
                        </tr>
                        <tr >
                           
                        </tr>
                    </table>
                </div>	
            </div>       
        </div>
        </div>
    </v-card>
      
</template>

<script>
    // import VueHtml2pdf  from 'vue-html2pdf'
    import jsPDF from 'jspdf';
    import _sPrePackingDispatch from "@/services/FrozenProduction/FrzChamberPrePackingDispatch.js";

    export default {
        components:{
            // VueHtml2pdf,
            // jsPDF
        },
        props:{
            PrePackingPdf: {
                default: null,
                type:Object,
            }
        },
        
        data(){
            return {
                
                contentRendered: false,
                PrePackingDispatch: {},
                progress: 0,
                controlValue: {},                
            }
        },
        computed:{
            //  ...mapFields(["controlValue"]),
            htmlToPdfOptions() {
             

                
            },         
            
        },
        methods:{
            initialize(){
                _sPrePackingDispatch.list(this.PrePackingPdf, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200){
                        this.PrePackingDispatch = r.data;
                       
                    }
                })
            },
            PrintPdf(){
               
                // const html = this.$refs.testHtml.innerHTML;

                const contentToExport = this.$refs.testHtml;

                const doc = new jsPDF({
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                });

                const fontSize = 8;
                doc.setFontSize(fontSize);
                
                const margins = { top: 10, bottom: 10, left: 10, right: 10 };
                
                const columnWidth = [[100, 150,100,150],[70,70,70,70,70]];

                const tables = contentToExport.querySelectorAll('table');

                doc.html(contentToExport, {
                    callback: () => {
                        doc.save("impresion.pdf");
                    },
                    x: margins.left,
                    y: margins.top,
                    html2canvas: {
                        scale: 0.3 // Escalar el contenido para reducir el tamaño
                    }
                });               
                
            },
            close(){
                this.$emit("closedialogPrintPdf");
            },
            
        },
        created(){
            this.initialize();
        },
        mounted(){
            
        }
    }
</script>

<style lang="scss" scoped>

.tablecss{    
    border-collapse: collapse;
}
.linea {
  border-top: 1px solid black;
  height: 2px;
  max-width: 200px;
  padding: 0;
  margin: 50px auto 0 auto;
}
.tableth{
    height: 30px;
    background-color: darkgrey !important ;
    text-align: center;
}
.report-info {
    display: flex;
    // padding: 20px;
    padding-bottom: 0px;
    .image-container {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-right: 25px;
        border: 1px solid #dadada;
        .user-image {
            height: 100%;
            min-width: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
}
.bg-surface-variant {
  background-color: #f5f5f5;
  padding: 10px;
}
.font-weight-bold {
  font-weight: bold;
}
.mt-1 {
  margin-top: 1rem;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; //fixed
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 210mm; /* Ancho de documento A4 */
  height: 297mm; /* Alto de documento A4 */
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}
</style>